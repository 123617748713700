<template>
  <div>
    <!-- 中文 -->
    <div v-if="$store.state.show">
      <h2>秘钥Key</h2>
      <p>
        TiSDK 的使用，需要配合秘钥 Key 进行鉴权，Key
        需要向商务申请，申请前请准备以下材料：企业营业执照、法人身份证信息、负责人联系方式、应用名称以及包名（安卓的ApplicationId、iOS的bundle
        id ）。
      </p>
      <p>
        秘钥Key有效期内，可以提供SDK对应权限的各种功能，没有Key或者Key失效等情况会限制使用SDK对应功能。
      </p>
    </div>
    <!-- 英文 -->
    <div v-else>
      <h2>Key</h2>
      <p>
        TiSDK needs to be authenticated with the secret key（You can ask our
        saler for the key）. Please prepare the following materials before
        applying: the secret key, legal person identity card information,
        contact information of the person in charge, application name and
        package name (ApplicationId of Android, bundle ID of iOS).
      </p>
      <p>
        During the validity period of the key, various functions corresponding
        to SDK permissions can be provided, and the use of SDK corresponding
        functions can be limited by the absence of the key or the failure of the
        key.
      </p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
h2 {
  font-size: 20px;
  font-weight: 500;
  color: #3c4858;
}
p {
  width: 763px;
  margin: 20px 0;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #3c4858;
}
</style>